import React, { useState, useEffect } from 'react';
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import Lottie from 'react-lottie';

import "./styles.css";

import { PopupButton } from '@typeform/embed-react'

// import video
import videoBg from "../src/assets/video.mp4";

import flamingo from './lottie/Flamingo.json';

const App = () => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: flamingo,
    // initialDelay: 3000,
    // isStopped:true
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice"
    // }
  };

  const [width, setWidth] = useState(window.innerWidth);

  const [autoplay, setAutoplay] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAutoplay(true);
    }, 1350); // Delay the animation by 2 seconds
    return () => clearTimeout(timeout);
  }, []);
  const [deadline, setDeadline] = useState(
    Date.UTC(2023, 3, 20, 12, 0, 0) // Set deadline to April 3, 2023 at 12:00:00 UTC
  );

  const handleNotifyMe = () => {
    // Handle the Notify Me button click
  };

  return (
    <section className="page">
      {/* overlay */}

      {/* <div className="overlay"> */}
      {/* <div className='hero-container-2'>
          <Lottie
            options={{ loop: false, animationData: flamingo, autoplay: autoplay }}
            height={width >= 700 ? 1280 / 3.2 : 1280 / 4.5}
            width={width >= 700 ? 1000 / 3.2 : 1280 / 4.5}
          />

        </div> */}


      {/* </div> */}
      {/* <video src={videoBg} autoPlay loop muted /> */}

      <div className="page__content">
        <div className='hero-container-2'>
          <Lottie
            options={{ loop: false, animationData: flamingo, autoplay: autoplay }}
            height={width >= 700 ? 1280 / 3.2 : 1280 / 4.5}
            width={width >= 700 ? 1000 / 3.2 : 1280 / 4.5}
          />
        </div>

        <h2>¡flamingo estará disponible pronto!</h2>
        <h3>
          deja tu número y te avisaremos cuando esté disponible en la tienda de aplicaciones
        </h3>
        <FlipClockCountdown
          className="flip-clock"
          to={deadline + 30 * 24 * 60 * 60 * 1000} // 30 days from now
          labels={['días', 'horas', 'minutos', 'segundos']}
          
        />
        <PopupButton id="nmIRkaj0" style={{ backgroundColor: 'transparent', borderWidth: 0 }}>
          <button className="btn" onClick={handleNotifyMe}>
            notifícame
          </button>
        </PopupButton>
      </div>
    </section>
  );
};

export default App;
